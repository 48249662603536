import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import SEO from '../blocks/layout/seo';
import LoadingBar from '../blocks/codes/loadingBar';
import { Firebase } from '../config/firebase';

export default function Home() {
      const [loading, setLoading] = useState(false);
      useEffect(() => {
            setLoading(true);
            async function asyncHandler(user) {
                  if (user) {
                        navigate('/setup');
                  } else {
                        navigate('/login');
                  }
            }

            Firebase.auth().onAuthStateChanged(asyncHandler);
      }, []);

      return (
            <>
                  <SEO title="NoCodeAPI Dashboard" />
                  {loading ? <LoadingBar /> : ''}
            </>
      );
}
